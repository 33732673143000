header
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  h1
    font-size: 48px
    font-weight: 800

  p.subtitle
    color: $color-gray

  .links-container
    display: flex
    flex-direction: row
    align-items: center
    gap: 16px


// Responsive
@media screen and (max-width: 745px)
  header
    h1
      font-size: 32px

@media screen and (max-width: 445px)
  header
    h1
      font-size: 24px

    p.subtitle
      font-size: 12px