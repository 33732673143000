//Variables
$button-padding: 12px 32px
$button-line-height: 20px
$button-radius: 6px
$button-font-size: 14px
$button-font-weight: 500

//Styles
.btn
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  gap: 4px
  font-size: $button-font-size
  line-height: $button-line-height
  border-radius: $button-radius
  padding: $button-padding
  cursor: pointer
  text-decoration: none
  color: $color-white
  transition: all .25s ease
  font-weight: $button-font-weight

  svg
    height: 18px
    width: 18px
.btn-primary
  color: $color-black
  background-color: $color-white-light
  &:hover
    background-color: $color-white

.btn-secondary
  background-color: $color-accent
  border: 1px solid $color-accent
  &:hover
    background-color: darken($color-accent, 4%)
    border-color: darken($color-accent, 4%)

.btn-outline
  border: 1px solid $color-accent
  &:hover
    background-color: $color-accent


.btn-ghost
  background-color: transparent
  border: 1px solid transparent
  &:hover
    background-color: $color-accent