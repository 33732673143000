body
  margin: 0
  background-color: $color-black
  background: linear-gradient(to bottom, $color-black, lighten($color-black, 1%))
  color: $color-white
  font-family: $font-default
  min-height: 100vh
  background-image: url("../../assets/medias/grid-header.png"), linear-gradient(to bottom, $color-black, lighten($color-black, 1%))
  background-size: cover
  background-repeat: no-repeat
  background-position: top
  background-attachment: fixed