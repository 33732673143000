$card-padding: 24px
$card-background: transparent
$card-radius: .5rem

.card
  border: 1px solid $color-accent
  background-color: $card-background
  color: $color-white
  padding: $card-padding
  border-radius: $card-radius
  background-color: $color-black


// Header
.card-header
  h2
    margin: 0 0 4px
    font-size: 20px
    font-weight: 700

  p
    margin: 0

  .subtitle
    color: $color-gray
    font-size: 14px


// Responsive
@media screen and (max-width: 478px)
  .card
    width: calc(100% - 48px)
    min-width: 300px