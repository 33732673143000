footer
  border-top: 1px solid $color-accent

  .business-wrapper
    p.business-name
      font-size: 16px
      font-weight: 600
      margin-top: 1rem

    p.business-location, p.business-copyright
      font-size: 12px
      color: $color-gray