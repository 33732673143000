//Import
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"

//Colors
$color-black: #030711
$color-white: #e1e7ef
$color-white-light: #f8fafc
$color-gray: #7f8ea3
$color-accent: #1D283A

//Fonts
$font-default: "Inter", sans-serif

//Modules
@import "modules/general"
@import "modules/header"
@import "modules/button"
@import "modules/card"
@import "modules/form"
@import "modules/footer"